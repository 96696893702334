.tournament {
  &__header {
    &-desc {
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-head {
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.player-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 5px;
      top: 1px;
      width: 7px;
      height: 13px;
      border: solid rgb(250, 250, 250);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: rgb(243, 78, 8);
    border: 2px solid rgb(243, 78, 8);
  }
}
